<template>
  <div>
<!--    <div class="tw-pt-36 wrapper md:tw-w-7/12 tw-px-6	tw-m-auto">-->

      <v-row v-for="(data,i) in questions" :key="i" class="tw-items-dcenter tw-mt-10  " v-show="counter===i">
        <v-col cols="12" md="12">
          <JobQuestionComponent  :question-length="questions.length" :count="counter+1" :question="data"/>
        </v-col>
        <v-col cols="12" md="12">
          <AssessmentPersonalityOptionType :selected-options="selectedOptions" :btn_loading="btn_loading" :questions="questions" :counter="counter+1" :question-type="data.question_type" :question-id="data.id" @select="getSelectedOption" @nextQuestion="nextQuestion" :options="data.options"/>

        </v-col>

      </v-row>
      </div>
</template>

<script>
import JobQuestionComponent from "@/components/dashboard/jobAssessment/JobQuestionComponent";
import AssessmentPersonalityOptionType from "@/components/dashboard/jobAssessment/AssessmentPersonalityOptionType";
export default {
  name: "AssessmentQuestionPersonalityTypeView",
  components: {AssessmentPersonalityOptionType, JobQuestionComponent},
  props: {
    questions:[Array],
    counter:[Number],
    selectedOptions:[Object,Array],
    btn_loading:[Boolean],

  },
  methods: {
    getSelectedOption(id,value){
      this.$emit('select',id,value)
    },
    nextQuestion(data){
      this.$emit('nextQuestion',data)
    }
  }
}
</script>

<style scoped>
.wrapper{
  /*max-height: 95vh;*/
  overflow: hidden;
}
.timer{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-left: 10px;
  letter-spacing: 0.1em;
}
.scroll {
  max-height: 95vh;
  overflow-y: scroll;
  overflow-x: hidden;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}
.warn{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */
  padding: 8px 8px 8px 16px;
  letter-spacing: 0.01em;
  color: #FF2E2E;
  background: #FFF8F8;
  border-radius: 8px;

}


</style>
