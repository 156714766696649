<template>
<div class="tw-mt-4">
  <v-row class="tw-flex tw-mr-8 tw-justify-center">
  <v-col cols="4" md="2" sm="3" class="" v-for="(option,i) in options" :key="i">
    <div  class="grade">
<!--      <v-col cols="12" md="12" sm="4">-->
        <h3>{{optionsLabels[i]}}</h3>
        <div class="tw-my-6 tw-flex tw-justify-center">
          <v-radio-group v-model="value">
            <v-radio color="#8F47F4" @change="toggleSelected(questionType,optionsLabels[i])"  :value="optionsLabels[i]">
            </v-radio>
          </v-radio-group>
        </div>
        <h5>{{option}}</h5>

<!--      </v-col>-->
    </div>
  </v-col>
  </v-row>
  <div class="tw-flex tw-mt-6 tw-justify-between">
    <div class="tw-hidden md:tw-block">
      <v-spacer></v-spacer>
    </div>
    <BaseButton :block="$vuetify.breakpoint.mobile" class="tw-mt-10 " :button-text="questions.length===counter?'Submit':'Next'"   :loading="btn_loading" @click="nextQuestion()" :disabled="!value"></BaseButton>
  </div>

</div>
</template>

<script>
import BaseButton from "@/components/reusables/BaseButton";
export default {
name: "AssessmentPersonalityOptionType",
  components: {BaseButton},
  props: {
    options:[Array],
    questionId:[Number,String],
    questions:[Array],
    counter:[Number,String],
    questionType:[String],
    btn_loading:[Boolean],
    selectedOptions:[Array,Object]
  },
  data() {
    return{
      value:'',
      optionsLabels: ["1", "2", "3", "4", "5", "6", "7"]

    }
  },
  computed:{
    getLoginUser() {
      return JSON.parse(sessionStorage.getItem('userData')) || null;
    }
  },
  methods: {
    toggleSelected(type,option){
        this.value = option
        console.log(this.value)
    },
    nextQuestion(){
      this.$emit('select',this.questionId,this.value.toString())
      this.$emit('nextQuestion',{
        job_id: this.$route.query.jobId,
        program_id: this.$route.query.id,
        email: this.getLoginUser.email,
        response: this.selectedOptions,
        test_type:this.$route.query.category.split(' ')[0].toLowerCase()

      })
    }
  }
}
</script>

<style scoped>
.grade h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: #475661;
  text-align: center;
}
.grade h3{
  font-family: IBM Plex Serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #475661;
  text-align: center;
}

</style>
