<template>
<div class="tw-mt-10 question">
    <h5>Question {{count}} OF {{questionLength}}</h5>
  <div class="tw-mt-6">
    <div class="scroll">
      <h3 v-html="question.content">aa</h3>

    </div>

    <img v-if="question.image" :style="[$vuetify.breakpoint.mobile?{width:'auto'}:{width:'450px'}]"  :src="question.image" alt="" class=" tw-w-450 tw-mb-12">
  </div>


</div>
</template>

<script>
export default {
name: "JobQuestionComponent",
  props: {
    question:[Object],
    count:[Number,String],
    questionLength:[Number,String]
  }
}
</script>

<style scoped>
.question h5{
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #475661;
  margin-bottom: 10px;

}
.question h3 {
  font-family: IBM Plex Serif,serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #1E323F;
  margin-bottom:20px;

}
.scroll {
  max-height: 400px;
  overflow-y: auto;

}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #c4c4c4 !important;
  max-height: 20px !important;
  border-radius: 6px;
}

</style>
